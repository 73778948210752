<template>
	<div :class="isMobile ? 'homebox' : 'pcbox'">
		<template v-if="isSearch && isMobile">
			<div class="topbox">
				<van-search
				  v-model="searchKey"
				  shape="round"
				  :placeholder="$t('common.searchkey')"
				  @click-input="onSearch"
				>
				</van-search>
				<span class="tips">{{ $t('home.tips') }}</span>
			</div>
			<div class="tabList">
				<ul>
					<li :class="key=='/home/recommend'?'active':''"><router-link to="/home/recommend">{{ $t('home.recommend') }}</router-link></li>
					<li :class="key=='/home/news'?'active':''"><router-link to="/home/news">{{ $t('home.news') }}</router-link></li>
				</ul>
			</div>
		</template>
		<router-view :key="key" />
		<div class="mfooter" v-if="isMobile">
			<div class="listItem">
				<!-- 用户协议 -->
				<p><router-link to="/clauseUser">{{ $t('footer.userService') }}</router-link></p>
				<!-- 隐私政策 -->
				<p><router-link to="/clauseService">{{ $t('footer.service') }}</router-link></p>
			</div>
			<p class="text-center lines">{{ $t('footer.telLabel') }}：{{ $t('footer.telValue') }}</p>
			<p class="text-center lines">{{ $t('footer.emailLabel') }}：{{ $t('footer.emailValue') }}</p>
			<div class="usemanual">
				<router-link to="/infos">
					GM STORE {{ $t('footer.useManual') }}
					<van-icon name="arrow" color="#666" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	
	const { proxy } = getCurrentInstance();
	const route = useRoute();
	const router = useRouter();
	const osType = ref(useUserStore().ostype);//游戏横竖屏
	const key = computed(() => {
		return route.path
	})
	const searchKey = ref('')
	const isSearch = computed(() => {
		return useConfigStore().isSearch
	})
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const params = ref({})
	onMounted(() => {
		const query = router.currentRoute.value.params
		params.value = Object.assign({},query)
		console.log(key)
	})
	const onSearch = () => {
		useConfigStore().setSearch(0)
		router.push({
		  name:'Search',
		  params: params.value
		});
	}
	const onClickNotice = () => {
		router.push({
		  name:'Personal'
		});
	}
</script>

<style lang="less" scoped>

.homebox{
	background: url('~@/assets/images/common/home_up_bg.png') no-repeat top;
	background-size: 100%;height: calc(100% - 5.625rem);overflow-y: scroll;
	.topbox{
		padding-top: 0.5rem;
		display: flex;align-items: center;
		/deep/input::-webkit-input-placeholder{ color: #fff; }
		/deep/input::-moz-placeholder{ color: #fff; }
		/deep/input::-ms-input-placeholder{ color: #fff; }
		/deep/input::placeholder{ color: #fff; }
		/deep/.van-search{
			width: 100%;
		}
		.tips{
			font-size: 0.8125rem;color: #fff;
		}
	}
	.noticeBtn{
		display: flex;align-items: center;
	}
	.tabList{
		padding: 0.3125rem 0.75rem;box-sizing: border-box;width: 100%;
		ul{
			display: flex;align-items: center;
			li{
				a{
					font-size: 0.9375rem;color: #fff;font-weight: 500;
				}
				&.active{
					a{
						font-size: 1.0625rem;
					}
				}
			}
			li + li{
				margin-left: 1rem;
			}
		}
	}
	.mfooter{
		width: 100%;padding: 2.5rem 1rem;box-sizing: border-box;
		.listItem{
			width: 100%;display: flex;align-items: flex-start;justify-content: center;
			color: #666;padding-top: 2rem;
			border-top: 1px solid #eee;
			p{
				padding: 0.625rem 0;line-height: 1.375rem;position: relative;
			}
			p+p{
				margin-left: 1rem;padding-left: 1rem;
				&::after{
					content: '';display: block;position: absolute;
					width: 1px;height: 30%;background: #666;opacity: 0.5;
					left: 0;top: 50%;transform: translate(0, -50%);
				}
			}
			a{
				cursor: pointer;font-size: 0.875rem;color: #333;
				&:hover{
					color: #51bac0;
					text-decoration: underline;text-underline-offset: 0.3rem;
				}
			}
		}
		.lines{
			color: #666;font-size: 0.875rem;line-height: 1.375rem;
		}
		.usemanual{
			width: 100%;position: relative;padding-bottom: 2.5rem;
			a{
				display: inline-block;padding: 0.375rem 0.75rem;border: 1px solid #ccc;border-radius: 1.25rem;
				position: absolute;left: 50%;top: 1.25rem;transform: translate(-50%,0);font-size: 0.875rem;color: #666;
			}
		}
	}
}
</style>
